<template>
  <!-- eslint-disable -->
  <div class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-8 align-center justify-space-between">
      <span class="font-weight-bold">
        User Data
      </span>
      <span class="caption-small">
        Home > User
      </span>
    </div>
    <v-row no-gutters>
      <v-col v-if="$roles($store.state.user.uid_roles, 'user-add')" col="12" class="col-md-4 pr-2 px-md-4">
        <v-btn depressed small block color="green" class="white--text text-capitalize" @click.prevent="OPEN_DETAIL()">
          <v-icon class="mr-1 body-1">
            mdi-plus-circle-outline
          </v-icon>
          <span class="caption-small">
            Add New User
          </span>
        </v-btn>
      </v-col>
      <v-col col="12" class="col-md-4 pl-2 px-md-4">
        <v-btn :outlined="!memberBirthday.show" :depressed="memberBirthday.show" small block color="blue" class="text-capitalize" @click.prevent="memberBirthday.show = !memberBirthday.show">
          <span class="caption-small">
            Today Birthday ({{ memberBirthday.data }})
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="col-md-4 pt-2 pt-md-0 px-md-4">
        <v-btn-toggle v-model="GridView.show">
          <v-btn :color="GridView.show ? 'grey darken-1' : 'orange darken-2'" class="white--text align-center" dark small @click.prevent="tableLimit = 10">
            <span class="caption-small text-capitalize">List</span>
            <v-icon x-small color="white" class="ml-1">mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn :color="GridView.show ? 'orange darken-2' : 'grey darken-1'"  class="white--text" dark small @click.prevent="tableLimit = 12">
            <span class="caption-small text-capitalize">Column</span>
            <v-icon small color="white" class="ml-1">mdi-view-grid</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" class="col-md-3 px-md-4 pt-4">
        <v-text-field
          v-model="search"
          :name="Math.random()"
          label="Search User"
          outlined
          dense
          hide-details
          append-icon="mdi-magnify"
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
        />
      </v-col>
      <v-col cols="6" class="pr-2 col-md-3 px-md-4 pt-4 pb-4">
        <v-select
          v-model="memberRoleType"
          :items="memberTypeSelect"
          item-text="name"
          item-value="value"
          :name="Math.random()"
          label="User Type"
          outlined
          dense
          hide-details
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
        />
      </v-col>
      <v-col cols="6" class="pr-0 pr-sm-2 col-md-3 px-md-4 pt-4 pb-4">
        <v-select
          v-model="trxSourceFilter"
          label="Joined Source"
          :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
          item-value="id"
          item-text="name"
          block
          outlined
          dense
          hide-details
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
          autocomplete="off"
          @change="trxUserAdminFilter = 0"
        />
      </v-col>
      <v-col cols="6" class="pr-2 col-md-3 px-md-4 py-0 py-sm-4">
        <v-select
          v-model.number="trxUserAdminFilter"
          :disabled="trxSourceFilter !== 'WA'"
          label="Select Admin"
          :items="[{ id: 0, name: 'All' }].concat($store.state.user_admin)"
          :item-text="(v) => {
            return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
          }"
          item-value="id"
          block
          outlined
          dense
          hide-details
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
          autocomplete="off"
        />
      </v-col>
      <v-col cols="12" class="col-md-6 px-md-4 pt-4 pt-sm-0">
        <v-menu
          ref="joinedMenuShow"
          v-model="joinedMenuShow"
          :return-value.sync="joinedDates"
          left
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="joinedDateRangeText"
              label="Joined Date"
              prepend-inner-icon="mdi-calendar-outline"
              readonly
              outlined
              dense
              hide-details
              clearable
              v-bind="attrs"
              v-on="on"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              @click:clear="joinedDates = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="joinedDates"
            no-title
            range
          >
            <v-divider />
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="joinedMenuShow = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.joinedMenuShow.save(joinedDates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" :class="[]" class="col-md-6 px-md-4 pt-4 pt-sm-0">
        <v-menu
          ref="achievementMenu"
          v-model="achievementMenu"
          :return-value.sync="achievementDates"
          left
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="achievementDateRangeText"
              label="Set Purchase Date"
              prepend-inner-icon="mdi-calendar-outline"
              readonly
              outlined
              dense
              hide-details
              clearable
              v-bind="attrs"
              v-on="on"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              @click:clear="achievementDates = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="achievementDates"
            no-title
            range
          >
            <v-divider />
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="achievementMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.achievementMenu.save(achievementDates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" class="pl-0 pl-sm-2 col-md-6 px-md-4 pt-4 pb-0 pb-sm-4">
        <v-select
          v-model.number="searchStatus"
          :items="[{ value: 1, name: 'Active' }, { value: 0, name: 'Not Active' }, { value: 1010, name: 'All' }]"
          item-text="name"
          item-value="value"
          :name="Math.random()"
          label="Joined Status"
          outlined
          dense
          hide-details
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
        />
      </v-col>
      <v-col v-if="$roles($store.state.user.uid_roles, 'user-acquisition-tools')" cols="12" class="d-flex align-center col-md-3 px-md-4 pt-4 pb-4">
        <v-btn depressed block depressed dark color="cyan" class="text-capitalize" style="letter-spacing:.5px;" @click.prevent="acquisitionsShow = true">
          Acquisition Tools
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex align-center col-md-3 px-md-4 pt-0 pt-sm-4 pb-4">
        <v-btn depressed block depressed dark color="purple" class="text-capitalize" style="letter-spacing:.5px;" @click.prevent="DOWNLOAD_CSV">
          Download Data ({{ tableTotal }})
        </v-btn>
      </v-col>
      <!-- <v-col v-if="$store.state.user.role === 'pusat'" cols="6" class="pl-2 col-md-3 px-md-4 pt-4">
        <v-autocomplete
          :name="Math.random()"
          autocomplete="off"
          v-model.number="filterLeadOnly"
          :search-input.sync="searchLeadOnly"
          :items="leadsOnly"
          item-value="id"
          item-text="name"
          outlined
          dense
          hide-details
          label="Select Lead"
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
          @click="searchLeadOnly = ''"
        >
          <template v-slot:item="data">
            <v-card class="d-flex justify-space-between caption pb-1" flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
              {{ data.item.name }}
              <v-spacer />
              <v-chip x-small class="caption pb-1">
                {{ data.item.role }}
              </v-chip>
            </v-card>
          </template>
        </v-autocomplete>
      </v-col> -->
      <v-col v-if="GridView.show" cols="12" class="px-md-1">
        <v-row no-gutters>
          <v-col v-for="(item, iGAgen) in table" :key="'gagen-' + iGAgen" cols="12" class="col-md-3 pa-3">
            <v-card
              outlined
              class="accent mb-2 rounded-lg text-left pt-1 px-2"
            >
              <v-card-subtitle class="d-flex align-center justify-space-between py-0 px-2 caption font-weight-bold">
                <div>
                  <v-avatar size="20" class="mr-1">
                    <img
                      alt="user"
                      :src="item.img || '/icon.png'"
                    >
                  </v-avatar>
                  <span class="caption-small c-pointer" @click.prevent="OPEN_MEMBER(item)">
                    {{ item.name }}
                  </span>
                </div>
                <span
                  :class="[$roleColor(item.role)]"
                  class="d-flex align-center ml-1 rounded-lg"
                  style="padding: 1px 3px 1px 3px;height: 14px;font-weight:400;line-height: 100%;color: #fff;font-size: 8px;"
                >
                  {{ item.role }}
                </span>
              </v-card-subtitle>
              <v-card-text class="py-4 px-2 caption-small" style="font-size: 12px;">
                {{ $GEN_ID(item) }}
                <!-- <div style="line-height: 100%;font-size: 12px;">
                  Lead: {{ item.lead_name || '-' }} (Acq {{ parseInt(item.acquisitions) || '0' }})
                </div> -->
                <!-- <div v-if="achievementDates.length === 2" class="primary--text pt-2 caption">
                  {{ item.achievement | price }}{{ achievementDates.length === 2 ? '/' + (Math.abs($DateRangeDays(achievementDates[0], achievementDates[1])) + 1) + 'd' : '' }}
                </div> -->
                <!-- <div v-if="ParseTotalMember(item.member_total, item.role)" style="min-width: 100px;" class="caption" v-html="ParseTotalMember(item.member_total, item.role)" /> -->
              </v-card-text>
              <v-card-actions class="justify-center">
                <div class="d-flex align-center justify-center" style="width: 100%;">
                  <v-btn x-small color="blue" dark depressed class="rounded-xl my-1 mx-1 caption-small text-capitalize px-1" style="width: 20%;" @click.prevent="OPEN_DETAIL(item)">
                    <v-icon class="d-inline caption-small">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="memberRoleType !== 'pusat'" x-small color="red" dark depressed class="rounded-xl my-1 mx-1 caption-small text-capitalize px-1" style="width: 20%;" @click.prevent="OPEN_MEMBER(item)">
                    <v-icon class="caption-small">
                      mdi-eye-outline
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="item.phone && memberRoleType !== 'pusat'" :href="'https://api.whatsapp.com/send?phone=' + $phone(item.phone) + '&text='" x-small color="green" dark depressed class="rounded-xl my-1 mx-1 caption-small text-capitalize px-1" target="_blank" style="width: 20%;">
                    <v-icon class="caption-small">
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="item.instagram && item.role !== 'customer' && memberRoleType !== 'pusat'" :href="'https://instagram.com/' + item.instagram" x-small color="pink" dark depressed class="rounded-xl my-1 mx-1 caption-small text-capitalize px-1" target="_blank" style="width: 20%;">
                    <v-icon class="caption-small">
                      mdi-instagram
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-4 pb-8">
            <v-pagination
              v-model.number="tablePage"
              :total-visible="7"
              circle
              dark
              depressed
              :length="Math.floor(tableTotal / tableLimit)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else cols="12" class="px-md-4">
        <v-card class="accent px-4 pt-4 text-center">
          <v-data-table
            dense
            :headers="memberRoleType === 'pusat' ? headersStokist : headers"
            :items="table"
            item-key="id"
            :server-items-length="tableTotal"
            :page.sync="tablePage"
            :items-per-page.sync="tableLimit"
            :options.sync="options"
            class="accent"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100]
            }"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.id="{ item }">
              <div class="caption-small c-pointer">
                {{ $GEN_ID(item) }}
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <div :class="[parseInt(item.status) ? '' : 'red--text']" class="py-2" style="position: relative;min-width: 150px;">
                <div class="caption-small">
                  <span class="c-pointer font-weight-medium tt-default">{{ item.name }}</span>
                  <span
                    :class="[$roleColor(item.role)]"
                    class="inline-block ml-1"
                    style="padding: 2px 4px 2px;line-height: 100%;border-radius: 3px 3px;color: #fff;font-size: 8px;"
                  >
                    {{ item.role === 'pusat' ? (parseInt(item.isstockist) ? 'Stokist' : 'Pusat') : item.role }}
                  </span>
                </div>
                <div class="py-1" style="line-height: 100%;font-size: 12px;">
                  {{ item.area_name || '-' }}
                </div>
                <!-- <div class="tt-default" style="line-height: 100%;font-size: 12px;">
                  Lead: {{ item.lead_name || '-' }}
                </div> -->
                <!-- <div v-if="ParseTotalMember(item.member_total, item.role)" style="min-width: 100px;" class="caption" v-html="ParseTotalMember(item.member_total, item.role)" /> -->
              </div>
            </template>
            <template v-slot:item.follow_up="{ item }">
              <div v-if="!parseInt(item.isstockist)" class="caption-small py-2">
                <div>
                  <v-btn x-small outlined color="lime" class="text-capitalize px-2" style="line-height: 100%;font-size: 9px;" @click.prevent="USER_FOLLOWUP_GET(item.id, item.name)">
                    ({{ item.follow_up_total }}) History
                  </v-btn>
                </div>
                <div class="pt-1">
                  {{ item.follow_up || '-' }}
                </div>
              </div>
            </template>
            <template v-slot:item.acquisitions="{ item }">
              <div class="caption-small">
                {{ parseInt(item.acquisitions) }}
                {{ achievementDates.length === 2 ? '/' + (Math.abs($DateRangeDays(achievementDates[0], achievementDates[1])) + 1) + 'd' : '' }}
              </div>
            </template>
            <template v-slot:item.achievement="{ item }">
              <div class="caption-small" style="min-width: 90px;">
                {{ item.achievement | price }}
                {{ achievementDates.length === 2 ? '/' + (Math.abs($DateRangeDays(achievementDates[0], achievementDates[1])) + 1) + 'd' : '' }}
              </div>
            </template>
            <template v-slot:item.repeat_order_stokist="{ item }">
              <div class="caption-small text-center" style="min-width: 60px;">
                <!-- {{ (parseInt(item.repeat_order) || 0) }}x -->
                <!-- <div v-if="SplitOrderPusatStokist(item)" class="" style="font-size: 9px;">{{ SplitOrderPusatStokist(item) }}</div> -->
                <div v-if="item.repeat_order_stokist" style="font-size: 9px;">{{ item.repeat_order_stokist }}</div>
                <div v-else style="font-size: 9px;">-</div>
              </div>
            </template>
            <template v-slot:item.omzet="{ item }">
              <div class="caption-small">
                {{ item.omzet | price }}
                {{ achievementDates.length === 2 ? '/' + (Math.abs($DateRangeDays(achievementDates[0], achievementDates[1])) + 1) + 'd' : '' }}
              </div>
            </template>
            <template v-slot:item.email="{ item }">
              <div class="caption-small text-truncate" style="max-width: 80px;">
                {{ item.email || '-' }}
              </div>
            </template>
            <template v-slot:item.phone="{ item }">
              <div class="caption-small">
                {{ item.phone ? $phone(item.phone) : '-' }}
              </div>
            </template>
            <template v-slot:item.first_order="{ item }">
              <div class="caption-small">
                {{ item.first_order ? $localDT(item.first_order, 'datelocal') : '-' }}
              </div>
            </template>
            <template v-slot:item.option="{ item }">
              <div class="d-flex flex-column justify-end">
                <div class="d-flex">
                  <v-btn x-small color="blue" dark depressed class="rounded-xl my-1 mr-1 caption-small text-capitalize px-1" style="min-width: 75px;" @click.prevent="OPEN_DETAIL(item)">
                    <v-icon class="d-inline caption-small">
                      mdi-pencil-outline
                    </v-icon>
                    <span class="ls-clear ml-1" style="font-size: 9px;">
                      Detail
                    </span>
                  </v-btn>
                  <v-btn v-if="memberRoleType !== 'pusat'" x-small color="red" dark depressed class="rounded-xl my-1 caption-small text-capitalize px-1" style="min-width: 75px;" @click.prevent="OPEN_MEMBER(item)">
                    <v-icon class="caption-small">
                      mdi-eye-outline
                    </v-icon>
                    <span class="ls-clear ml-1" style="font-size: 9px;">
                      View
                    </span>
                  </v-btn>
                </div>
                <div class="d-flex">
                  <v-btn v-if="item.phone && memberRoleType !== 'pusat'" :href="'https://api.whatsapp.com/send?phone=' + $phone(item.phone) + '&text='" x-small color="green" dark depressed class="rounded-xl my-1 mr-1 caption-small text-capitalize px-1" target="_blank" style="min-width: 75px;">
                    <v-icon class="caption-small">
                      mdi-whatsapp
                    </v-icon>
                    <span class="ls-clear" style="font-size: 9px;">
                      Whatsapp
                    </span>
                  </v-btn>
                  <v-btn v-if="item.instagram && item.role !== 'customer' && memberRoleType !== 'pusat'" :href="'https://instagram.com/' + item.instagram" x-small color="pink" dark depressed class="rounded-xl my-1 mr-1 caption-small text-capitalize px-1" target="_blank" style="min-width: 75px;">
                    <v-icon class="caption-small">
                      mdi-instagram
                    </v-icon>
                    <span class="ls-clear" style="font-size: 9px;">
                      Instagram
                    </span>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="newMember.show"
      max-width="450"
      persistent
      scrollable
      light
    >
      <v-card v-if="newMember.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1 text-capitalize"
        >
          {{ newMember.data.id ? 'Update' : 'Create New' }} {{ newMember.data.role === 'pusat' ? 'Stokist' : newMember.data.role }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newMember.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="px-2 pt-8 pb-0">
            <v-select
              v-model="newMember.data.role"
              :name="Math.random()"
              :items="parseInt($store.state.user.isstockist) ? [
                { value: 'customer', name: 'Customer' },
                { value: 'reseller', name: 'Reseller' }
              ] : [
                { value: 'agen', name: 'Agen' },
                { value: 'customer', name: 'Customer' },
                { value: 'reseller', name: 'Reseller' },
                { value: 'pusat', name: 'Offline Store' }
              ]"
              item-text="name"
              autocomplete="off"
              item-value="value"
              label="User Type"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <!-- <v-autocomplete
              v-if="$store.state.user.role === 'pusat'"
              :name="Math.random()"
              autocomplete="off"
              v-model.number="newMember.data.lead"
              :search-input.sync="searchLead"
              :items="itemsLeads"
              item-value="id"
              item-text="name"
              outlined
              dense
              hide-details
              label="Select Lead"
              @click="searchLead = ''"
              class="mb-4 required"
            >
              <template v-slot:item="data">
                <v-card class="d-flex justify-space-between" flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                  {{ data.item.name }}
                  <v-spacer />
                  <v-chip small>
                    {{ data.item.role }}
                  </v-chip>
                </v-card>
              </template>
            </v-autocomplete> -->
            <v-text-field
              v-model="newMember.data.name"
              :name="Math.random()"
              label="Name"
              autocomplete="disabled"
              outlined
              required
              dense
              hide-details
              class="mb-4 required"
            />
            <v-text-field
              v-if="($store.state.user.role === 'pusat' && newMember.data.role !== 'customer' && newMember.data.role !== 'pusat')"
              v-model="newMember.data.area_name"
              :name="Math.random()"
              label="Area Name"
              autocomplete="disabled"
              outlined
              required
              dense
              hide-details
              class="mb-4 required"
            />
            <v-menu
              v-if="newMember.data.role !== 'customer' && newMember.data.role !== 'pusat' && newMember.data.role !== 'agen'"
              ref="menuBirthday"
              v-model="menuBirthday"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newMember.data.birthday"
                  label="Birthday date"
                  readonly
                  outlined
                  dense
                  hide-details
                  autocomplete="disabled"
                  class="mb-4"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="pickerBirthday"
                v-model="newMember.data.birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1970-01-01"
                @change="$refs.menuBirthday ? $refs.menuBirthday.save(newMember.data.birthday) : false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.username"
              :name="Math.random()"
              label="Username"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              @keyup="newMember.data.username = newMember.data.username ? newMember.data.username.toLowerCase().split(' ').join('') : ''"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.email"
              :name="Math.random()"
              autocomplete="disabled"
              type="email"
              label="Email"
              outlined
              dense
              hide-details
              class="mb-4"
              :class="[newMember.data.role === 'customer' ? '' : 'required']"
              :rules="[newMember.data.role !== 'customer' ? $validateEmail(newMember.data.email) : true]"
            />
            <v-text-field
              v-model="newMember.data.phone"
              :name="Math.random()"
              label="Phone / Whatsapp Number"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              @keypress="$NumberOnly"
              @keyup="newMember.data.phone = $reformatPhone(newMember.data.phone)"
            />
            <!-- <v-text-field
              v-if="newMember.data.role !== 'customer' && newMember.data.role !== 'pusat' && newMember.data.role !== 'agen'"
              v-model="newMember.data.instagram"
              :name="Math.random()"
              autocomplete="disabled"
              label="Instagram Username"
              prefix="@"
              outlined
              dense
              hide-details
              class="mb-4"
              :class="[newMember.data.role === 'customer' ? '' : 'required']"
            /> -->
            <v-autocomplete
              v-model.number="newMember.data.area"
              :name="Math.random()"
              :search-input.sync="areaquery"
              :items="areas"
              item-value="id"
              item-text="area_name"
              outlined
              dense
              hide-details
              label="District, City, Province (Shipping Origin)"
              class="mb-4"
              autocomplete="disabled"
              @change="areaSync()"
            ></v-autocomplete>
            <v-textarea
              v-model="newMember.data.address"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Full Address"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <label v-if="newMember.data.role !== 'customer' && newMember.data.password" class="caption">
              Password : {{
                $passwordCheck(newMember.data.password) <= 25
                ? 'weak'
                  : $passwordCheck(newMember.data.password) <= 50
                  ? 'weak'
                    : $passwordCheck(newMember.data.password) <= 75
                    ? 'medium'
                      : $passwordCheck(newMember.data.password) <= 100
                      ? 'strong' : '-'
              }}
            </label>
            <v-progress-linear
              v-if="newMember.data.role !== 'customer' && newMember.data.password"
              :value="$passwordCheck(newMember.data.password)"
              :color="
                $passwordCheck(newMember.data.password) <= 25
                ? 'red'
                  : $passwordCheck(newMember.data.password) <= 50
                  ? 'orange'
                    : $passwordCheck(newMember.data.password) <= 75
                    ? 'lime'
                      : $passwordCheck(newMember.data.password) <= 100
                      ? 'green' : 'grey'
              "
              class="mb-4"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              :name="Math.random()"
              autocomplete="new-password"
              v-model="newMember.data.password"
              :label="newMember.data.id ? 'Change Password' : 'New Password'"
              outlined
              dense
              hide-details
              class="mb-4 required"
              :type="togglePassword ? 'text' : 'password'"
              :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePassword = !togglePassword"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              :name="Math.random()"
              autocomplete="new-password"
              v-model="newMember.data.password_confirm"
              :label="newMember.data.id ? 'Confirm Password' : 'Confirm Password'"
              outlined
              dense
              hide-details
              class="mb-4 required"
              :type="togglePassword ? 'text' : 'password'"
              :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePassword = !togglePassword"
            />
            <v-select
              v-if="$store.state.user.role === 'pusat' && newMember.data.role !== 'pusat' && newMember.data.role !== 'customer'"
              v-model.number="newMember.data.show_web"
              :name="Math.random()"
              autocomplete="disabled"
              :items="[
                { value: 0, name: 'None' },
                { value: 1, name: 'Yes' }
              ]" 
              item-text="name"
              item-value="value"
              label="Show on the web member List"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-if="newMember.data.role !== 'customer'"
              v-model.number="newMember.data.status"
              :name="Math.random()"
              autocomplete="disabled"
              :items="[
                { value: 0, name: 'Not active' },
                { value: 1, name: 'Active' }
              ]" 
              item-text="name"
              item-value="value"
              label="Member Status"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-divider class="mb-4" />
            <!-- <v-textarea
              v-if="$store.state.user.role === 'pusat'"
              v-model="newMember.data.wa_message"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Whatsapp Message for Web"
              outlined
              dense
              hide-details
              class="mb-4"
            /> -->
            <v-textarea
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.invoice_note"
              :name="Math.random()"
              rows="2"
              label="Default Invoice Notes"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              class="mb-4"
            />
            <!-- <v-textarea
              v-if="$store.state.user.role === 'pusat'"
              v-model="newMember.data.notes_admin"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Additional Notes (pusat only)"
              outlined
              dense
              hide-details
              class="mb-4"
            /> -->
            <v-select
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              v-model="newMember.data.joined_source"
              label="User Source"
              :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
              item-value="id"
              item-text="name"
              block
              outlined
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
              @change="newMember.data.joined_admin = 0"
            />
            <v-select
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              :disabled="newMember.data.joined_source !== 'WA'"
              v-model.number="newMember.data.joined_admin"
              label="Select Admin"
              :items="[{ id: 0, name: 'None' }].concat($store.state.user_admin)"
              :item-text="(v) => {
                return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
              }"
              item-value="id"
              block
              outlined
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
            />
            <v-menu
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              ref="joinedMenu"
              v-model="joinedMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newMember.data.joined"
                  label="Joined Date"
                  hide-details
                  outlined
                  dense
                  persistent-hint
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newMember.data.joined"
                no-title
                @input="$refs.joinedMenu ? $refs.joinedMenu.save(newMember.data.joined) : false, joinedMenu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-8 pb-2">
          <v-btn
            v-if="$store.state.user.role === 'pusat'"
            text
            color="red"
            small
            class="pb-1 text-capitalize"
            @click="DeleteMember()"
          >
            Remove
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="$roles($store.state.user.uid_roles, 'user-add')"
            depressed
            color="blue"
            dark
            small
            class="text-capitalize rounded-xl"
            :disabled="loading"
            @click="MEMBER_PROCESS()"
          >
            {{ newMember.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="syncPoint.show"
      persistent
      scrollable
      max-width="350"
      light
    >
      <v-card light class="pa-4">
        <div class="pa-4 text-center font-weight-bold">
          eBright Sync Point
        </div>
        <v-divider />
        <v-text-field
          v-model="syncPoint.point"
          outlined
          hide-details
          dense
          class="my-4 rounded-pill"
          @keypress="$NumberOnly"
        />
        <div class="d-flex justify-space-between">
          <v-btn outlined x-small color="red" class="rounded-pill" @click.prevent="syncPoint.show = false">
            Close
          </v-btn>
          <v-btn depressed x-small color="primary" class="rounded-pill" @click.prevent="syncPointProcess(dialogMember.data.id)">
            Sync Point
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogMember.show"
      persistent
      scrollable
      max-width="560"
      light
    >
      <v-card v-if="dialogMember.data" light>
        <v-card-text class="pb-8 px-0 invoice-area member-detail-area" style="min-height: calc(100vh - 177px);">
          <v-row no-gutters>
            <!-- <v-col cols="3" class="img pa-4 text-center">
              &nbsp;
              <img :src="dialogMember.data.img || '/images/no-image.jpg'" class="block">
            </v-col> -->
            <v-col cols="9">
              <div class="pt-10 pl-8">
                <div class="logo">
                  <img src="/new-logo-ebrightskin.png">
                </div>
                <!-- <div class="pt-1 text-uppercase font-weight-medium primary--text text-darken-2" style="font-size: 12px;">
                  {{ dialogMember.data.role }}
                </div> -->
              </div>
            </v-col>
            <v-col cols="3" class="qr pa-4 text-center">
              <div id="qrcode" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-4 img-profile pt-2">
              <div class="img-area">
                <img :src="dialogMember.data.img || '/images/no-image.jpg'">
              </div>
              <div class="title primary--text pt-4 pb-1">
                {{ dialogMember.data.name }}
              </div>
              <div class="text-uppercase font-weight-bold" style="font-size: 12px;">
                {{ dialogMember.data.role }} &sdot; {{ dialogMember.data.username }} &sdot; {{ $GEN_ID(dialogMember.data) }}
              </div>
              <div class="" style="font-size: 12px;">
                Joined, {{ $localDT(dialogMember.data.joined || dialogMember.data.created, 'datelocal') }} 
               <!--  <span>
                  Deposit {{ $price(userDeposit) }}
                </span> -->
                <!-- <br> -->
                <!-- Lead: {{ dialogMember.data.lead_name || '-' }} -->
              </div>
            </v-col>
          </v-row>
          <!-- <v-divider class="mt-3 mb-2" /> -->
          <v-row no-gutters class="pt-4">
            <v-col cols="6" class="pl-6 pr-2 text-right">
              <div class="caption font-weight-medium">
                Profile
              </div>
              <div class="caption">
                Born, {{ dialogMember.data.birthday ? $localDT(dialogMember.data.birthday, 'datelocal') : '-' }} <v-icon style="font-size: 15px;margin-left: 2px;">mdi-calendar-outline</v-icon><br>
                {{ dialogMember.data.address }} <v-icon style="font-size: 15px;margin-left: 2px;">mdi-map-marker-outline</v-icon><br>
                {{ dialogMember.data.area_name }}
              </div>
            </v-col>
            <v-col cols="6" class="pr-6 pl-2">
              <div class="caption font-weight-medium">
                Contact
              </div>
              <div class="caption">
                <v-icon style="font-size: 15px;margin-right: 2px;">mdi-email-outline</v-icon> {{ dialogMember.data.email }}<br>
                <v-icon style="font-size: 15px;margin-right: 2px;">mdi-phone-outline</v-icon> {{ dialogMember.data.phone ? $phone(dialogMember.data.phone) : '-' }}<br>
                <span v-if="dialogMember.data.instagram">
                  <v-icon style="font-size: 15px;margin-right: 2px;">mdi-instagram</v-icon> @{{ dialogMember.data.instagram }}
                </span>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters class="pt-0">
            <v-col cols="12" class="font-weight-black text-center pa-3">
            </v-col>
            <v-col cols="12" class="font-weight-medium primary--text text-center" style="font-size: 10px;">
              <div style="font-size: 1rem;">
                {{ (parseInt(dialogMember.data.poin_to_lead || 0) - parseInt(dialogMember.data.poin_used || 0)).toLocaleString() }}
                <v-icon class="body-2 ml-1" color="primary" style="padding-bottom: 2px;">
                  mdi-gold
                </v-icon>
              </div>
              POINT : {{ parseInt(dialogMember.data.poin_used || 0).toLocaleString() }}/{{ parseInt(dialogMember.data.poin_to_lead || 0).toLocaleString() }}
            </v-col>
            <v-col v-if="$store.state.user.role === 'pusat' && !parseInt($store.state.user.uid)" cols="12" class="font-weight-medium primary--text text-center py-2" style="font-size: 10px;">
              <v-btn small depressed color="primary" class="caption rounded-pill text-capitalize" @click.prevent="syncPoint.show = true">
                Sync Point <span v-if="syncPoint.point">[{{ syncPoint.point }}]</span>
              </v-btn>
            </v-col>
            <v-col cols="12" class="font-weight-medium primary--text text-center" style="font-size: 10px;">
              <div style="font-size: .75rem;">
                Omzet: {{ parseInt(dialogMember.data.omzet || 0) | price }} &sdot;
                Hpp: {{ parseInt(dialogMember.data.hpp || 0) | price }}
              </div>
            </v-col>
          </v-row> -->
          <!-- <v-row no-gutters class="px-10">
            <v-col cols="12" class="font-weight-black text-center px-3 pb-2 pt-1">
              &sdot; &sdot; &sdot;
            </v-col>
            <v-col cols="12">
              <div class="font-weight-medium grey--text text-darken-2 mb-1" style="font-size: 12px;">
                Rewards :
              </div>
              <pre class="black--text" style="font-size: 12px;line-height: 130%;white-space: pre-wrap;font-family:inherit;" v-html="SplitMember(dialogMember.rewards)" />
            </v-col>
            <v-col cols="12">
              <div class="font-weight-medium grey--text text-darken-2" style="font-size: 12px;">
                Notes :
              </div>
              <pre class="black--text" style="font-size: 12px;line-height: 130%;white-space: pre-wrap;font-family:inherit;">{{ dialogMember.data.notes_admin || '-' }}</pre>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-divider class="no-print" />
        <v-card-actions class="px-4 no-print">
          <v-btn
            color="orange"
            small
            text
            class="text-capitalize font-weight-normal"
            @click="dialogMember.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="$store.state.screenWidth > 767"
            color="blue"
            small
            dark
            outlined
            class="text-capitalize font-weight-normal rounded-xl"
            @click="print()"
          >
            Print Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="UserFollowUp.show"
      max-width="450"
      scrollable
      light
    >
      <v-card>
        <v-card-title
          class="caption py-3 subtitle-1"
        >
          Follow Up : <strong class="ml-2">{{ UserFollowUp.title }}</strong>
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="UserFollowUp.show = false, UserFollowUp.data = []"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4">
          <div v-if="$roles($store.state.user.uid_roles, 'user-add-followup')" class="my-2 pa-4 green lighten-5 rounded-lg">
            <v-textarea
              v-model="UserFollowUp.active.notes"
              hide-details
              dense
              outlined
              block
              rows="2"
              class="mb-2 rounded-lg"
              label="Notes :"
            />
            <v-btn depressed color="green darken-2" block small dark class="text-capitalize rounded-pill" @click.prevent="USER_FOLLOWUP_PROCESS()">
              Create Follow Up
            </v-btn>
          </div>
          <div v-for="(sh, iSh) in UserFollowUp.data" :key="'stock-history-' + iSh" class="d-flex justify-center flex-column fill-width pa-4 grey lighten-4 my-2 rounded-lg caption-small">
            <div class="d-flex justify-space-between align-center">
              {{ $localDT(sh.created, 'datetime') }}
              <span>
                {{ (sh.admin_name ? sh.admin_name + '/' : '') + sh.lead_name }}
              </span>
            </div>
            <v-divider />
            <div class="d-flex f-width">
              <div class="f-width d-flex align-center">
                <v-icon small class="mr-1">
                  mdi-format-quote-open
                </v-icon>
                {{ sh.notes || '-' }}
              </div>
              <v-btn v-if="parseInt($DiffHours(sh.created)) < 1" x-small text color="red" class="text-capitalize caption-small mt-1" style="float: right;letter-spacing: .3px;line-height: 100%;" @click.prevent="USER_FOLLOWUP_DELETE(sh.id)">
                <v-icon x-small color="red" class="mr-1">
                  mdi-delete
                </v-icon>
                Remove
              </v-btn>
            </div>
          </div>
          <div v-if="UserFollowUp.isShowMore" class="text-center py-4">
            <v-btn depressed x-small color="indigo" dark class="rounded-xl" @click.prevent="USER_FOLLOWUP_GET(null, null, true)">
              Load More 
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AcquisitionTools :show="acquisitionsShow" @close="acquisitionsShow = false" />
  </div>
</template>

<script>
let searchInterval = null
let searchAreaTimeout = null
const newMember = {
  id: null,
  name: '',
  area_name: '',
  birthday: null,
  username: '',
  instagram: '',
  email: '',
  password: '',
  lead: 0,
  province: 0,
  city: 0,
  district: 0,
  address: '',
  lat: '',
  lng: '',
  notes: '',
  role: 'customer',
  isstockist: 0,
  status: 1,
  joined_source: '',
  joined_admin: 0
}
const defaultHeaders = [
  { text: '#ID', value: 'id' },
  {
    text: 'User',
    sortable: true,
    value: 'name'
  },
  { text: 'Phone', value: 'phone' },
  { text: 'Email', value: 'email' },
  // { text: 'Acquisitions', value: 'acquisitions' },
  // { text: 'Omzet', value: 'omzet' },
  { text: 'Purchase', value: 'achievement' },
  { text: 'Repeat', value: 'repeat_order_stokist' },
  { text: 'Follow Up', value: 'follow_up' },
  { text: 'Opt', value: 'option', sortable: false }
]
const defaultHeadersStokist = [
  { text: '#ID', value: 'id' },
  {
    text: 'User',
    sortable: true,
    value: 'name'
  },
  { text: 'Phone', value: 'phone' },
  { text: 'Email', value: 'email' },
  // { text: 'Acquisitions', value: 'acquisitions' },
  // { text: 'Omzet', value: 'omzet' },
  { text: 'Omzet', value: 'achievement' },
  { text: 'Opt', value: 'option', sortable: false }
]
export default {
  data: (vm) => ({
    acquisitionsShow: false,
    syncPoint: {
      show: false,
      point: 0
    },
    dialogMember: {
      show: false,
      rewards: [],
      data: null
    },
    GridView: {
      show: false,
      data: {
        pusat: {
          data: [],
          total: 0
        },
        distributor: {
          data: [],
          total: 0
        },
        agen: {
          data: [],
          total: 0
        },
        reseller: {
          data: [],
          total: 0
        },
        customer: {
          data: [],
          total: 0
        }
      }
    },
    filterLeadOnly: 0,
    searchLeadOnly: '',
    leadsOnly: [],
    loading: false,
    togglePassword: false,
    menuBirthday: false,
    newMember: {
      show: false,
      data: null
    },
    memberRoleType: '',
    search: '',
    searchStatus: 1,
    searchLead: '',
    leads: [],
    provinces: [],
    cities: [],
    districts: [],
    tableLoading: true,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 10,
    options: {},
    headers: defaultHeaders,
    headersStokist: defaultHeadersStokist,
    achievementDates: [],
    achievementMenu: false,
    joinedDates: [],
    joinedMenuShow: false,
    memberBirthday: {
      show: false,
      data: 0
    },
    areaquery: '',
    areas: [],
    joinedDate: new Date().toISOString().substr(0, 10),
    joinedDateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    joinedMenu: false,
    UserFollowUp: {
      show: false,
      title: '',
      data: [],
      isShowMore: true,
      active: {
        id: null,
        user: vm.$store.state.user ? vm.$store.state.user.id : 0,
        uid: vm.$store.state.uid ? vm.$store.state.user.uid : 0,
        customer_id: 0,
        page: 1,
        notes: ''
      }
    },
    userDeposit: 0,
    trxUserAdminFilter: 0,
    trxSourceFilter: ''
  }),
  watch: {
    trxUserAdminFilter () {
      this.MEMBER_GET(this.search)
    },
    trxSourceFilter () {
      this.MEMBER_GET(this.search)
    },
    areaquery: function (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    'memberBirthday.show' () {
      this.MEMBER_GET(this.search)
    },
    'GridView.show' (v) {
      if (v) {
        // this.LoadGridView()
        this.MEMBER_GET(this.search)
      }
    },
    menuBirthday (val) {
      val && setTimeout(() => (this.$refs.pickerBirthday.activePicker = 'YEAR'))
    },
    tableAchievement: function () {
      this.MEMBER_GET(this.search)
    },
    search: function (v) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.MEMBER_GET(v)
      }, 300)
    },
    searchStatus: function (v) {
      this.MEMBER_GET(this.search)
    },
    memberRoleType () {
      this.MEMBER_GET(this.search)
    },
    filterLeadOnly: function (v) {
      if (parseInt(v)) {
        this.MEMBER_GET(this.search, parseInt(v))
      } else {
        this.MEMBER_GET(this.search)
      }
    },
    searchLeadOnly: function () {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.$store.dispatch('MEMBER_GET', '?page=1&limit=25&searchLeadFromRole=pusat&q=' + this.search).then((res) => {
          if (res.status) {
            this.leadsOnly = [{ id: 0, name: 'All Lead', role: '~' }].concat([{ id: parseInt(this.$store.state.user.id), name: this.$store.state.user.name, role: this.$store.state.user.role }]).concat(res.data.data.data.filter(r => r.role !== 'customer'))
          } else {
            this.leadsOnly = []
          }
        })
      }, 300)
    },
    searchLead: function (v) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        if (this.newMember.data) {
          const searchLead = '&searchLeadFromRole=' + this.newMember.data.role
          this.$store.dispatch('MEMBER_GET', '?page=1&limit=25&q=' + (v || '') + searchLead).then((res) => {
            if (res.status) {
              this.leads = res.data.data.data.filter(r => parseInt(r.id) !== parseInt(this.newMember.data.id))
            } else {
              this.leads = []
            }
          })
        }
      }, 300)
    },
    options: {
      handler () {
        this.MEMBER_GET()
      },
      deep: true
    },
    joinedDates: function (v) {
      if (v) {
        if (v.length > 1 || !v.length) {
          this.MEMBER_GET(this.search)
        }
      } else {
        this.MEMBER_GET(this.search)
      }
    },
    achievementDates: function (v) {
      if (v) {
        if (v.length > 1 || !v.length) {
          this.MEMBER_GET(this.search)
        }
      } else {
        this.MEMBER_GET(this.search)
      }
    },
    tablePage () {
      this.MEMBER_GET(this.search)
    }
  },
  computed: {
    joinedDateRangeText () {
      return this.joinedDates.join(' ~ ')
    },
    achievementDateRangeText () {
      return this.achievementDates.join(' ~ ')
    },
    memberTypeSelect () {
      if (this.$store.state.user) {
        if (this.$store.state.user.isstockist) {
          return [{ value: '', name: 'Customer & Reseller' }, { value: 'customer', name: 'Customer Only' }, { value: 'reseller', name: 'Reseller Only' }]
        } else {
          return [{ value: '', name: 'Agen & Customer & Reseller' }, { value: 'agen', name: 'Agen' }, { value: 'customer', name: 'Customer Only' }, { value: 'reseller', name: 'Reseller Only' }, { value: 'pusat', name: 'Offline Store' }]
        }
      }
      return []
    },
    itemsLeads () {
      const leads = parseInt(this.newMember.data.lead) && !this.leads.length
        ? [{ id: this.$store.state.user.id, name: this.$store.state.user.name, role: this.$store.state.user.role }].concat([{ id: this.newMember.data.lead, name: this.newMember.data.lead_name, role: this.newMember.data.lead_role }]).concat(this.leads)
        : [{ id: this.$store.state.user.id, name: this.$store.state.user.name, role: this.$store.state.user.role }].concat(this.leads)
      return leads
    }
  },
  created () {
    if (this.$store.state.user.role !== 'pusat') {
      this.headers = this.headers.filter(r => r.value !== 'lead_name')
    }
  },
  mounted () {
    this.MEMBER_GET()
    this.$store.dispatch('SHIPPING_GET', '?province_only=true').then((res) => {
      if (res.status) {
        this.provinces = res.data.data
      }
    })
  },
  methods: {
    BALANCE (user) {
      this.$store.dispatch('BALANCE', user)
        .then((res) => {
          if (res.status) {
            this.userDeposit = isNaN(parseInt(res.data.data)) ? 0 : parseInt(res.data.data) || 0
          }
        })
    },
    SplitOrderPusatStokist (item) {
      if (parseInt(item.repeat_order)) {
        if (parseInt(item.repeat_order_pusat)) {
          const stokist = (parseInt(item.repeat_order) - parseInt(item.repeat_order_pusat)) || 0
          return parseInt(item.repeat_order_pusat) + 'x Pusat, ' + stokist + 'x Stokist'
        }
      }
      return ''
    },
    async DOWNLOAD_CSV () {
      const dt = await this.MEMBER_GET(this.search, this.memberRoleType, true)
      if (dt.status) {
        if (dt.data.data.data.length) {
          const prepareData = []
          const data = dt.data.data.data || []
          for (let x = 0; x < parseInt(dt.data.data.total); x++) {
            const d = Object.assign({}, data[x])
            d.refid = this.$GEN_ID(d)
            d.phone = '"' + (d.phone ? ('62' + d.phone) : d.phone) + '"'
            prepareData.push(d)
          }
          await this.$downloadAsCSV(prepareData, 'ID;REFID;NAME;PHONE;EMAIL;ROLE', ['id', 'refid', 'name', 'phone', 'email', 'role'], 'EB_USER_DATA')
        }
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong!' })
      }
    },
    USER_FOLLOWUP_GET (cid, custName, loadMore) {
      this.UserFollowUp.show = true
      this.UserFollowUp.title = custName || this.UserFollowUp.title
      this.UserFollowUp.active.customer_id = cid || this.UserFollowUp.active.customer_id
      this.UserFollowUp.active.user = this.$store.state.user.id
      // this.UserFollowUp.data = []
      if (!loadMore) {
        this.UserFollowUp.page = 1
        this.UserFollowUp.data = []
      } else {
        this.UserFollowUp.page += 1
      }
      this.$store.dispatch('USER_FOLLOWUP_GET', {
        cid: this.UserFollowUp.active.customer_id,
        q: '?limit=12&page=' + (this.UserFollowUp.page || 1)
      }).then((res) => {
        if (res.status) {
          if (!loadMore) {
            this.UserFollowUp.data = res.data.data || []
          } else {
            this.UserFollowUp.data = this.UserFollowUp.data.concat(res.data.data)
          }
          if (res.data.data.length < 12) {
            this.UserFollowUp.isShowMore = false
          } else if (res.data.data.length >= 12) {
            this.UserFollowUp.isShowMore = true
          }
        }
      })
    },
    USER_FOLLOWUP_PROCESS () {
      if (this.UserFollowUp.active) {
        const data = Object.assign({}, this.UserFollowUp.active)
        if (!parseInt(data.user) || !parseInt(data.customer_id)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Invalid user or customer!' })
          return false
        }
        if (!data.notes) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please input notes!' })
          return false
        }
        this.$store.dispatch('USER_FOLLOWUP_PROCESS', data).then((res) => {
          if (res.status) {
            this.$store.commit('SNACKBAR', { show: true, text: data.id ? 'Updated!' : 'Created!' })
            this.UserFollowUp.active.notes = ''
            this.USER_FOLLOWUP_GET()
            this.MEMBER_GET(this.search)
          }
        })
      }
    },
    USER_FOLLOWUP_DELETE (id) {
      if (!confirm('Remove this follow up?')) {
        return false
      }
      this.$store.dispatch('USER_FOLLOWUP_DELETE', id).then((res) => {
        if (res.status) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Removed!' })
          this.USER_FOLLOWUP_GET()
          this.MEMBER_GET(this.search)
        }
      })
    },
    syncPointProcess (memberID) {
      if (memberID) {
        this.$store.dispatch('EBRIGHT_POIN', {
          uid: memberID,
          poin: parseInt(this.syncPoint.point) || 0,
          notes: ''
        }).then((res) => {
          this.syncPoint.show = false
          this.OPEN_MEMBER(this.dialogMember.data)
          this.$store.commit('SNACKBAR', { show: true, text: 'Sync Point Updated' })
        })
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'No member Selected' })
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    areaSync () {
      const id = parseInt(this.newMember.data.area)
      if (id) {
        const findSelectedArea = this.areas.find(r => parseInt(r.id) === parseInt(id))
        if (findSelectedArea) {
          this.newMember.data.district = parseInt(findSelectedArea.kecamatan_id) || 0
          this.newMember.data.city = parseInt(findSelectedArea.city_id) || 0
          this.newMember.data.province = parseInt(findSelectedArea.province_id) || 0
        }
      }
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then(res => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    print () {
      window.print()
    },
    LoadGridView () {
      this.memberRoleType = ''
      this.GridView.data.pusat.data = []
      this.GridView.data.pusat.total = 0
      this.GridView.data.distributor.data = []
      this.GridView.data.distributor.total = 0
      this.GridView.data.agen.data = []
      this.GridView.data.agen.total = 0
      this.GridView.data.reseller.data = []
      this.GridView.data.reseller.total = 0
      this.GridView.data.customer.data = []
      this.GridView.data.customer.total = 0
      // this.MEMBER_GET(this.search, 'pusat')
      // this.MEMBER_GET(this.search, 'distributor')
      // this.MEMBER_GET(this.search, 'agen')
      // this.MEMBER_GET(this.search, 'reseller')
      this.MEMBER_GET(this.search, 'customer')
    },
    OPEN_DETAIL (member) {
      if (member) {
        this.newMember.data = Object.assign({}, member)
      } else {
        this.newMember.data = Object.assign({}, newMember)
      }
      this.userDeposit = 0
      if (parseInt(this.newMember.data.id) && (this.newMember.role === 'customer' || this.newMember.data.role === 'reseller')) {
        this.BALANCE(parseInt(this.newMember.data.id))
      }
      this.newMember.data.birthday = this.newMember.data.birthday ? this.$localDT(this.newMember.data.birthday, 'datedefault') : null
      this.newMember.data.joined = this.newMember.data.joined ? this.$localDT(this.newMember.data.joined, 'datedefault') : null
      this.newMember.data.wa_message = this.$stringToEmoticon(this.newMember.data.wa_message, this.$store.state.emoticons.emoticons)
      this.newMember.data.password = ''
      this.newMember.data.password_confirm = ''
      this.areaGet('?q=', this.newMember.data.area)
      if (this.newMember.data.province) {
        this.loadCities()
      }
      this.newMember.show = true
    },
    OPEN_MEMBER (member) {
      this.userDeposit = 0
      if (parseInt(member.id) && (member.role === 'customer' || member.role === 'reseller')) {
        this.BALANCE(parseInt(member.id))
      }
      this.dialogMember.data = Object.assign({}, member)
      this.dialogMember.data.birthday = this.dialogMember.data.birthday ? this.$localDT(this.dialogMember.data.birthday, 'datedefault') : null
      this.dialogMember.data.joined = this.dialogMember.data.joined ? this.$localDT(this.dialogMember.data.joined, 'datedefault') : null
      this.dialogMember.data.wa_message = this.$stringToEmoticon(this.dialogMember.data.wa_message, this.$store.state.emoticons.emoticons)
      this.dialogMember.data.password = ''
      this.dialogMember.data.password_confirm = ''
      this.areaGet('?q=', this.dialogMember.data.area)
      if (this.dialogMember.data.province) {
        this.loadCities()
      }
      this.dialogMember.rewards = ''
      this.$store.dispatch('MEMBER_GET_ONE', member.id).then((res) => {
        if (res.status) {
          this.dialogMember.data.poin_to_lead = parseInt(res.data.data.poin_to_lead) || 0
          this.dialogMember.data.poin_used = parseInt(res.data.data.poin_used) || 0
        }
      })
      this.$store.dispatch('POIN_GET', '?user=' + member.id).then((res) => {
        if (res.status) {
          if (res.data.data.total > 0) {
            this.syncPoint.point = res.data.data.point || 0
            const d = res.data.data.data
            for (let r = 0; r < d.length; r++) {
              const newR = d[r].reward_title + ', ' + (d[r].poin).toLocaleString() + 'point'
              this.dialogMember.rewards += (r > 0 ? ' || ' : '') + newR
            }
          }
        }
      })
      this.dialogMember.show = true
      setTimeout((r) => {
        this.MEMBER_QR(this.dialogMember.data)
      }, 300)
    },
    async MEMBER_GET (q, type, downloadCsv) {
      const status = this.searchStatus
      this.tableLoading = true
      if (downloadCsv) {
        this.tableLoading = false
      }
      let { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (this.GridView.show) {
        sortDesc = false
        page = this.tablePage
        itemsPerPage = this.tableLimit
      }
      const limitResult = downloadCsv ? '&limit=' + (this.tableTotal) : (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      const pageStart = downloadCsv ? '&page=0' : (parseInt(page) > 0 ? '&page=' + page : '')
      const query = '?t=' + pageStart + limitResult + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') + (type ? '&role=' + type : (this.memberRoleType ? '&role=' + this.memberRoleType : ''))

      let Achievement = ''
      if (this.achievementDates.length === 2) {
        if ((new Date(this.achievementDates[0])).getTime() < (new Date(this.achievementDates[1])).getTime()) {
          Achievement = '&achievementFrom=' + this.achievementDates[0] + '&achievementTo=' + this.achievementDates[1]
        } else {
          Achievement = '&achievementFrom=' + this.achievementDates[1] + '&achievementTo=' + this.achievementDates[0]
        }
      }
      let addFilter = (parseInt(this.trxUserAdminFilter) ? '&joined_admin=' + this.trxUserAdminFilter : '') + (this.trxSourceFilter ? '&joined_source=' + this.trxSourceFilter : '')
      let JoinedDate = ''
      if (this.joinedDates.length === 2) {
        if ((new Date(this.joinedDates[0])).getTime() < (new Date(this.joinedDates[1])).getTime()) {
          JoinedDate = '&joinedFrom=' + this.joinedDates[0] + '&joinedTo=' + this.joinedDates[1]
        } else {
          JoinedDate = '&joinedFrom=' + this.joinedDates[1] + '&joinedTo=' + this.joinedDates[0]
        }
        addFilter += JoinedDate
      }
      return await this.$store.dispatch('MEMBER_GET', query + (q ? ('&q=' + q.replace('EB.21.', '')) : '') + (parseInt(status) === 1 || parseInt(status) === 0 ? '&status=' + status : '') + '&leadOnly=' + (parseInt(this.filterLeadOnly) || 0) + Achievement + (this.memberBirthday.show ? '&birthday=1' : '') + (addFilter)).then((res) => {
        if (downloadCsv) {
          return res
        } else {
          this.memberBirthday.data = 0
          if (res.status) {
            if (typeof type === 'string') {
              this.GridView.data[type].data = res.data.data.data || []
              this.GridView.data[type].total = parseInt(res.data.data.total) || 0
            } else {
              this.memberBirthday.data = parseInt(res.data.data.birthday) || 0
              this.table = res.data.data.data
              this.tableTotal = parseInt(res.data.data.total) || 0
            }
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        }
      })
    },
    MEMBER_PROCESS () {
      const member = Object.assign({}, this.newMember.data)
      if (!member.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Name is empty' })
        return false
      }
      if (member.role !== 'customer' && member.role !== 'pusat') {
        if (!member.area_name) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Area name is empty' })
          return false
        }
      }
      if (!this.$USERNAME_CHECK(member.username) && member.role !== 'customer') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Username' })
        return false
      }
      if (!this.$validateEmail(member.email) && member.role !== 'customer') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid email' })
        return false
      }
      if (!this.$validatePhone(member.phone)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid phone / whatsapp number' })
        return false
      }
      if (!member.instagram && member.role !== 'customer' && member.role !== 'pusat' && member.role !== 'agen') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input instagram account' })
        return false
      }
      if (!parseInt(member.province)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select province' })
        return false
      }
      if (!parseInt(member.city)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select city' })
        return false
      }
      if (!parseInt(member.district)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select district' })
        return false
      }
      if (!member.address) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input address' })
        return false
      }
      if (member.role !== 'customer' && !member.password && !member.id) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Password is empty' })
        return false
      }
      let x = false
      if (member.password && member.role !== 'customer') {
        if (member.password !== member.password_confirm) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Confirm password not match' })
          return false
        }
        if (this.$passwordCheck(member.password) <= 50) {
          x = true
          if (!confirm('Password is weak, continue ?')) {
            return false
          }
        }
      }
      //  else if (member.role === 'customer') {
      //   member.password = new Date().getUTCMilliseconds()
      // }
      if (member.wa_message) {
        member.wa_message = this.$emoticonToString(member.wa_message, this.$store.state.emoticons.emoticons)
      }
      if (member.role === 'pusat') {
        member.isstockist = 1
      } else {
        member.isstockist = 0
      }
      if (parseInt(this.$store.state.user.uid)) {
        member.joined_source = 'WA'
        member.joined_admin = parseInt(this.$store.state.user.uid)
      }
      if (member.id) {
        if (member.password && !x && member.role !== 'customer') {
          if (!confirm('Change the password ?')) {
            return false
          }
        }
        this.$store.dispatch('MEMBER_UPDATE', member).then((res) => {
          if (res.status) {
            if (res.data.data === 'username_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Username already taken!' })
            } else if (res.data.data === 'email_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Email already registered. Please change with different email' })
            } else if (res.data.data === 'phone_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Phone number already registered. Please change with different number' })
            } else if (res.data.data === 'email_phone_required') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Please fill email and phone number' })
            } else {
              this.MEMBER_GET()
              this.$store.commit('SNACKBAR', { show: true, text: 'Member updated' })
              this.newMember.show = false
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('MEMBER_ADD', member).then((res) => {
          if (res.status) {
            if (res.data.data === 'username_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Username already taken!' })
            } else if (res.data.data === 'email_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Email already registered. Please change with different email' })
            } else if (res.data.data === 'phone_exist') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Phone number already registered. Please change with different number' })
            } else if (res.data.data === 'email_phone_required') {
              this.$store.commit('SNACKBAR', { show: true, text: 'Please fill email and phone number' })
            } else {
              this.MEMBER_GET()
              this.$store.commit('SNACKBAR', { show: true, text: 'New member created' })
              this.newMember.show = false
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    DeleteMember () {
      const member = Object.assign({}, this.newMember.data)
      if (!confirm('Are you sure want to remove this member ?')) {
        return false
      }
      if (!parseInt(member.id)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'No member selected' })
        return false
      }
      this.$store.dispatch('MEMBER_DELETE', member).then((res) => {
        if (res.status) {
          this.newMember.show = false
          this.MEMBER_GET()
          this.$store.commit('SNACKBAR', { show: true, text: 'Member removed' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    loadCities () {
      if (!this.newMember.data) {
        return false
      }
      if (parseInt(this.newMember.data.province)) {
        this.$store.dispatch('SHIPPING_GET', '?province=' + this.newMember.data.province).then((res) => {
          if (res.status) {
            this.cities = res.data.data || []
            this.loadDistricts()
          }
        })
      }
    },
    loadDistricts () {
      if (!this.newMember.data) {
        return false
      }
      if (parseInt(this.newMember.data.city)) {
        this.$store.dispatch('SHIPPING_DISTRICT_GET', '?city=' + this.newMember.data.city).then((res) => {
          if (res.status) {
            this.districts = res.data.data.data || []
          }
        })
      }
    },
    ParseTotalMember (members, role) {
      if (members) {
        const m = members.split(',')
        let distributor = 0
        let agen = 0
        let reseller = 0
        let customer = 0
        let total = 0
        for (let i = 0; i < m.length; i++) {
          const mm = m[i] ? m[i].split(':') : ['', 0]
          if (mm[0] === 'distributor') {
            distributor = parseInt(mm[1])
          } else if (mm[0] === 'agen') {
            agen = parseInt(mm[1])
          } else if (mm[0] === 'reseller') {
            reseller = parseInt(mm[1])
          } else if (mm[0] === 'customer') {
            customer = parseInt(mm[1])
          }
          total += parseInt(mm[1])
        }
        if (role === 'pusat') {
          return 'D:' + distributor + ', A:' + agen + ', R:' + reseller + ', C:' + customer + '<small class="d-block">Total: ' + total + '</small>'
        } else if (role === 'distributor') {
          return 'A:' + agen + ', R:' + reseller + ', C:' + customer + '<small class="d-block">Total: ' + total + '</small>'
        } else if (role === 'agen') {
          return 'R:' + reseller + ', C:' + customer + '<small class="d-block">Total: ' + total + '</small>'
        } else if (role === 'reseller') {
          return 'C:' + customer + '<small class="d-block">Total: ' + total + '</small>'
        } else {
          return -''
        }
        // return {
        //   d: distributor || 0,
        //   a: agen || 0,
        //   r: reseller || 0,
        //   c: customer || 0,
        //   t: total || 0
        // }
      }
      return ''
    },
    MEMBER_QR (member, el, size) {
      const qr = this.$Qr()
      qr.qr.update({
        data: 'https://ebrightskin.id/' + this.$GEN_ID(member),
        image: '/icon.png',
        width: size || 75,
        height: size || 75
      })
      setTimeout(() => {
        qr.qr.append(document.getElementById(el || 'qrcode'))
      }, 200)
    },
    SplitMember (rewards) {
      if (!rewards) {
        return '-'
      }
      rewards = rewards.split('||')
      let newRewards = ''
      rewards.map((r) => {
        if (r) {
          newRewards += '<span class="mb-2" style="display: inline-block;padding: 2px 6px 4px 6px;margin-right: 8px;border: solid 1px #c6893f;border-radius: 2px;">' + r + '</span>'
        }
      })
      return newRewards
    }
  }
}
</script>

<style lang="scss">
.member-detail-area {
  .qr {
    position: relative;
    >#qrcode {
      display: block;
      margin: 0 auto;
      canvas {
        border: solid 1px #c6893f;
        border-radius: 7px;
        overflow: hidden;
      }
    }
  }
  .logo {
    >img {
      width: 147px;
      height: 42px;
    }
  }
  .img-profile {
    position: relative;
    text-align: center;
    .img-area {
      position: relative;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background-color: #c6893f;
      padding: 2px;
      text-align: center;
      overflow: hidden;
      img {
        display: block;
        margin: 0 auto;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}
</style>
